import MovieList from "../components/MovieList";

const Home = () => {

    

    //   console.log(movies)

  return (
    <>
        <MovieList />
    </>

  )
}

export default Home